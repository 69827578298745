'use client'
import * as React from 'react'
import { Controller, FormProvider } from 'react-hook-form'
import { useReactSelectProps } from '../../../../../hooks/useReactSelectProps'
import { makeGetErrorFromFormState, RequirementsSelectContainer } from '../../../../shop/features/requirements/common'
import Select, { Props as SelectProps } from 'react-select'
import { isNonNullableSelectOption, isNullableSelectOption } from '../../../../../typescript/guards/isSelectOption'
import { Collapse } from '../../../../../common/style/Collapse'
import dynamic from 'next/dynamic'
import { fieldsByTab, fields } from '../common/fields'
import { useFranchiseMembership } from '../hooks/useFranchiseMembership'
import styled from '@emotion/styled'
import { franchisePageTheme } from '../styles/franchisePageTheme'
import { franchisePagePrimarySelectStyles } from '../styles/franchisePagePrimarySelectStyles'
import { FranchiseErrorMessage } from '../atoms/FranchiseErrorMessage'
import { CustomChevronDropdownIndicator } from '../atoms/CustomChevronDropdownIndicator'
import { franchiseTabs } from '../constants/franchiseTabs'
import { useFranchiseForm } from '../hooks/useFranchiseForm'
import { pluralize } from '../../../../../utilities/pluralize'
import isNil from 'lodash/isNil'
import { NextImage } from '../../../../../common/functionality/NextImage'
import { cdnUrl } from '../../../../../common/constants/s3ImageUrls'
import { Clickable } from '../../../../../common/component-library/buttons/Clickable'
import FacebookSvg from '../assets/facebook.svg'
import XSvg from '../assets/x.svg'
import YouTubeSvg from '../assets/youtube.svg'
import InstagramSvg from '../assets/instagram.svg'
import { scrollIntoView } from '../../../../../utilities/scrollIntoView'
import { useWordPressFranchiseLocationsData } from '../hooks/useWordPressFranchiseLocationsData'
import { stringify } from 'flatted'
import { LazyMarkdownRenderer } from '../../../../../common/components/MarkdownRenderer/LazyMarkdownRenderer'
import { externalFranchiseUrlBase, externalOwnFranchiseUrlBase } from '../../../../../common/constants/routes'
import { newTabLinkProps } from '../../../../../utilities/newTabLinkProps'
import { NextLink } from '../../../../../common/functionality/NextLink'
import {
  franchiseServiceQueryParamName,
  postAuthFormStateQueryParamName,
  tabQueryParamName,
} from '../../../../../common/constants/queryParams'
import { useAccount } from '../../../../auth/hooks/useAccount'
import { useLogInState } from '../../../../auth/authState'
import { typesafeEntries } from '../../../../../typescript/guards/typesafeEntries'
import { useSignUpCompoundFranchiseLocationData } from '../hooks/useSignUpCompoundFranchiseLocationData'
import { useSetFranchiseAccountModal } from '../hooks/useSetFranchiseAccountModal'
import { useRepopulatFormPostAuth } from '../hooks/useRepopulatFormPostAuth'
import { useFranchiseDeploymentData } from '../hooks/useHost/useFranchiseDeploymentData'
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params'
import { theme } from '../../../../../styles/theme'
import { useDevice } from '../../../../../hooks/useDevice'

const LazyPwaNotification = dynamic(() => import('../molecules/PwaNotification').then((mod) => mod.PwaNotification))

const LazyLocationSelectedContent = dynamic(() =>
  import('../organisms/LocationSelectedContent').then((x) => x.LocationSelectedContent)
)

const LazyGetStartedImageSection = dynamic(() =>
  import('../molecules/EagerGetStartedImageSection').then((x) => x.EagerGetStartedImageSection)
)
const LazyPaymentScreen = dynamic(() => import('../organisms/PaymentScreen').then((x) => x.PaymentScreen))

const FontOverride = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(211, 211, 211, 0.5), rgba(211, 211, 211, 0.5)), url(/backgrounds/gray-honeycomb.svg);
  background-repeat: repeat;

  * {
    font-family: ${franchisePageTheme.fonts.manrope};
  }
`

const FacebookAnchor = styled.a`
  :hover {
    path {
      fill: ${franchisePageTheme.colors.indiaInk};
      transition: fill 0.15s;
    }
  }
`

const NonFacebookSocialAnchor = styled.a`
  :hover {
    rect {
      fill: ${franchisePageTheme.colors.indiaInk};
      transition: fill 0.15s;
    }
  }
`

const franchisePageContentId = 'franchise-page-content'

export function FranchisePage() {
  React.useEffect(() => {
    const style = document.createElement('style')
    const franchiseBarStyles = /* css */ `
    #nprogress .bar {
      background: ${franchisePageTheme.colors.raichuOrange} !important; /* For browsers that do not support gradients  */

      background-image: linear-gradient(
        to left top,
        ${theme.colors.grayscale000},
        ${franchisePageTheme.colors.raichuOrange},
        ${franchisePageTheme.colors.yamabukiGold},
        ${franchisePageTheme.colors.fleurDeSelCaramel}
      ) !important;
    }`
    style.innerHTML = franchiseBarStyles
    document.head.appendChild(style)
    return () => {
      document.head.removeChild(style)
    }
  }, [])
  const [isPaymentScreen, setIsPaymentScreen] = React.useState(false)

  const [, setParams] = useQueryParams({
    [franchiseServiceQueryParamName]: ArrayParam,
    [tabQueryParamName]: StringParam,
  })
  const { reviews } = useWordPressFranchiseLocationsData()
  const formMethods = useFranchiseForm({ paymentRequired: isPaymentScreen })
  const { isAuthenticated } = useAccount()

  const { setFranchiseAccountModal } = useSetFranchiseAccountModal()
  const useFranchiseMembershipReturn = useFranchiseMembership({
    formMethods,
    setIsPaymentScreen,
    setFranchiseAccountModal,
  })

  const { isLoading, franchiseLocationOptions, credits, serviceOptions } = useFranchiseMembershipReturn

  const {
    control,
    getValues,
    watch,
    setValue,
    formState,
    reset,
    resetField,
    handleSubmit,
    defaultValues,
    setFocus,
    clearErrors,
  } = formMethods

  const locationSelectProps = useReactSelectProps({
    options: franchiseLocationOptions,
    loading: isLoading?.franchiseLocationsQuery || isLoading?.setFranchiseLocationMutation,
    components: { DropdownIndicator: CustomChevronDropdownIndicator },
  })
  const getError = makeGetErrorFromFormState(formState)
  const [, setLogInState] = useLogInState()

  useRepopulatFormPostAuth({ formMethods, useFranchiseMembershipReturn, setIsPaymentScreen })

  const values = watch()

  const tab = values[fields.tab]

  React.useEffect(() => {
    clearErrors()
  }, [tab, clearErrors])

  const locationSelected = !!values[fields.location]
  const compoundFranchiseLocationData = useSignUpCompoundFranchiseLocationData()

  const { franchiseHomeRoute } = useFranchiseDeploymentData()

  const { isInPwaStandaloneMode, isTouchScreen } = useDevice()
  return (
    <FormProvider {...formMethods}>
      <FontOverride id={franchisePageContentId}>
        {!isPaymentScreen && (
          <div className="absolute w-full">
            <div className="relative h-[390px] w-full lg:h-[420px]">
              <NextImage
                className="object-cover object-right"
                src={cdnUrl('upgrade-labs/franchise-hero-woman-laying-red-light.webp')}
                fill
                alt={'woman-laying-red-light-hero'}
              />
            </div>
          </div>
        )}

        <div className={'z-[1] flex justify-center p-3'}>
          <form
            className="flex w-full max-w-standardPageMaxContentWidth flex-col"
            onSubmit={handleSubmit(() => {
              if (!isAuthenticated) {
                const url = new URL(location.href)
                const allParams = new URLSearchParams(url.search)
                allParams.set(postAuthFormStateQueryParamName, encodeURIComponent(JSON.stringify(values)))
                url.search = allParams.toString()

                setLogInState((s) => ({
                  ...s,
                  isLoggingIn: true,
                  loginOptions: {
                    appState: {
                      postAuthRedirectUri: url.toString(),
                    },
                    realize_form: 'ulf-signup',
                    screen_hint: 'signup',
                    ...compoundFranchiseLocationData,
                  },
                }))
              } else {
                const activeTab = values[fields.tab]

                typesafeEntries(fieldsByTab)
                  .filter(([actionTab]) => actionTab !== activeTab)
                  .flatMap(([, otherFields]) => otherFields)
                  .forEach((f) => resetField(f))

                setIsPaymentScreen(true)
              }
            })}
          >
            {isPaymentScreen ? (
              <LazyPaymentScreen {...useFranchiseMembershipReturn} setIsPaymentScreen={setIsPaymentScreen} />
            ) : (
              <>
                <div className="mb-[96px] flex w-full items-center justify-between gap-3 self-end rounded-xl bg-grayscalewhite p-4 lg:mb-[71px] lg:max-w-[420px] lg:p-6">
                  <div className="w-full lg:max-w-[377px]">
                    <div
                      className="mb-1 text-base font-semibold text-grayscale900"
                      css={{ fontFamily: franchisePageTheme.fonts.oswald }}
                    >
                      Location
                    </div>

                    <RequirementsSelectContainer className="w-full" style={{ marginBottom: 0, paddingTop: 0 }}>
                      <Controller
                        control={control}
                        name={fields.location}
                        render={({ field: { value, ref, onBlur } }) => (
                          <Select
                            {...(locationSelectProps as SelectProps)}
                            isSearchable={false}
                            onBlur={onBlur}
                            ref={ref}
                            styles={franchisePagePrimarySelectStyles(!!getError(fields.location))}
                            placeholder={'Select Location...'}
                            openMenuOnFocus
                            defaultValue={locationSelectProps.options.find(
                              (x) => isNullableSelectOption(x) && x.value === getValues(fields.location)
                            )}
                            value={locationSelectProps.options.find(
                              (x) => isNullableSelectOption(x) && x.value === value
                            )}
                            onChange={(next) => {
                              if (isNonNullableSelectOption(next)) {
                                const change = () => {
                                  const currentValue = values[fields.location]
                                  const nextValue = next?.value
                                  if (currentValue !== nextValue) {
                                    // no need to do anything if the value doesn't change, but anytime it does, form needs to
                                    // reset fully
                                    reset({ ...defaultValues, [fields.location]: nextValue })
                                  }
                                }
                                change()
                              }
                            }}
                          />
                        )}
                      />

                      <FranchiseErrorMessage message={getError(fields.location)} />
                    </RequirementsSelectContainer>
                  </div>
                  {!isNil(credits) && (
                    <div className="inline-flex h-[69px] flex-col items-center justify-center gap-0.5 rounded border border-grayscale100 bg-grayscale000 p-2">
                      <div className="text-2xl font-medium text-grayscale900">{credits}</div>
                      <div className="text-center !font-oswald text-xs font-bold uppercase tracking-wide text-grayscale500">
                        {pluralize('Credit', credits)}
                      </div>
                    </div>
                  )}
                </div>
                {!isInPwaStandaloneMode && isTouchScreen && <LazyPwaNotification />}

                <div className="flex flex-col gap-4">
                  <Collapse isOpen={locationSelected}>
                    {locationSelected && <LazyLocationSelectedContent {...useFranchiseMembershipReturn} />}
                  </Collapse>
                  <div className="flex flex-col gap-8 rounded-xl bg-grayscalewhite p-4 lg:items-center lg:px-8 lg:pb-8 lg:pt-12">
                    <div
                      className={
                        '!font-oswald text-[44px] font-medium leading-[50px] text-grayscale900 lg:max-w-[480px] lg:text-center'
                      }
                    >
                      Don&apos;t Know Where To Start? We&apos;ve Got You Covered.
                    </div>
                    <LazyGetStartedImageSection />

                    <div className="font-manrope text-base font-medium tracking-tight text-grayscale700 lg:max-w-[662px] lg:text-center">
                      One of our wellness coaches will walk you through your first visit. We always start by
                      understanding your fitness goals, needs, and preferences. Your input will guide our
                      recommendations. Experience the future of wellness with our Free Cell Health Analysis ($50 value)
                      and receive a Free Service ($55 value) at the end of your tour at Upgrade Labs. Discover how our
                      Technologies can help you achieve your wellness goals through a personalized plan. Plus, get a
                      taste of our cutting-edge offerings. Schedule your Free Cell Health Analysis and learn which
                      technologies will help you reach your goals.
                    </div>
                    <Clickable
                      franchisePrimary
                      large
                      stretch
                      elementProps={{
                        type: 'button',
                        className: 'max-w-[400px] self-center',
                        onClick: () => {
                          setFocus(fields.date)
                          // scrollTo with behavior smooth was not operating smoothly for some reason so i am resorting to
                          // using this library

                          const top = document.getElementById(franchisePageContentId)
                          if (top) {
                            scrollIntoView(top, () => {
                              clearErrors()
                              setValue(fields.tab, franchiseTabs.upgrades)
                              const introTourId = serviceOptions.find((x) => x.label === 'Intro Tour')?.value

                              if (introTourId) setValue(fields.service, [introTourId])
                              setParams({
                                [franchiseServiceQueryParamName]: ['Intro Tour'],
                                tab: franchiseTabs.upgrades,
                              })
                            })
                          }
                        },
                      }}
                    >
                      Free Intro Tour and Cell Health Analysis
                    </Clickable>

                    <div className="w-full lg:-mx-8 lg:w-[calc(100%+64px)]">
                      <Collapse isOpen={!!reviews.length}>
                        <div className="inline-flex min-h-[339px] max-w-full flex-col items-start justify-start gap-2 pt-4 lg:pt-8">
                          <div className="inline-flex items-center justify-start gap-2 self-stretch pb-2 lg:justify-center lg:px-[56px]">
                            <div className="!font-oswald text-[28px] font-medium text-grayscale900">
                              Member Testimonials
                            </div>
                          </div>
                          <div className="-mx-4 w-[calc(100%+32px)] overflow-x-auto lg:-mx-0 lg:w-full">
                            <div className="inline-flex items-start justify-start gap-3 px-4 py-2 lg:px-8">
                              {reviews.map((x) => (
                                <div
                                  key={stringify(x)}
                                  className="inline-flex min-h-[258px] w-[189px] flex-col items-start justify-start gap-2 self-stretch rounded-xl bg-indiaInk px-3 py-4 lg:w-[229px]"
                                >
                                  <div className="inline-flex items-center justify-start">
                                    {Array.from({ length: 5 }, (_, i) => (
                                      <svg
                                        key={i}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="16"
                                        viewBox="0 0 17 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M7.68957 1.41753C8.03908 0.341836 9.5609 0.341839 9.91041 1.41753L10.9626 4.65585C11.1189 5.13691 11.5672 5.46262 12.073 5.46262L15.478 5.46262C16.609 5.46262 17.0793 6.90995 16.1643 7.57476L13.4096 9.57616C13.0004 9.87347 12.8291 10.4005 12.9855 10.8815L14.0376 14.1199C14.3872 15.1955 13.156 16.09 12.2409 15.4252L9.48627 13.4238C9.07705 13.1265 8.52293 13.1265 8.11371 13.4238L5.35904 15.4252C4.444 16.09 3.21282 15.1955 3.56234 14.1199L4.61453 10.8815C4.77084 10.4005 4.5996 9.87347 4.19039 9.57616L1.43571 7.57477C0.520673 6.90995 0.990939 5.46262 2.12199 5.46262L5.52695 5.46262C6.03277 5.46262 6.48107 5.13691 6.63738 4.65585L7.68957 1.41753Z"
                                          className="fill-yamabukiGold"
                                        />
                                      </svg>
                                    ))}
                                  </div>
                                  <div className="shrink grow basis-0 self-stretch font-manrope text-[13px] font-medium tracking-tight text-grayscalewhite">
                                    <LazyMarkdownRenderer markdown={x.content} />
                                  </div>
                                  <div className="self-stretch text-right font-manrope text-sm font-bold text-grayscalewhite">
                                    — {x.reviewerName}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                  <div className="inline-flex h-[146px] flex-col items-center justify-center gap-5 rounded-xl bg-grayscalewhite p-6 lg:p-8">
                    <div className="!font-oswald text-[28px] font-medium text-grayscale900">Follow us on Social</div>
                    <div className="inline-flex items-center justify-start gap-4 py-0.5">
                      <FacebookAnchor
                        className="h-[33px] w-[33px] rounded-[250px]"
                        href={'https://www.facebook.com/TheUpgradeLabs/'}
                      >
                        <FacebookSvg />
                      </FacebookAnchor>
                      <NonFacebookSocialAnchor
                        className="h-[33px] w-[33px] rounded-[250px]"
                        href={'https://www.instagram.com/upgradelabs/'}
                      >
                        <InstagramSvg />
                      </NonFacebookSocialAnchor>
                      <NonFacebookSocialAnchor
                        className="h-[33px] w-[33px] rounded-[250px]"
                        href={'https://x.com/theupgradelabs'}
                      >
                        <XSvg />
                      </NonFacebookSocialAnchor>
                      <NonFacebookSocialAnchor
                        className="h-[33px] w-[33px] rounded-[250px]"
                        href={'https://www.youtube.com/channel/UCNMTGffP_MbYk-ozjhaL4lw'}
                      >
                        <YouTubeSvg />
                      </NonFacebookSocialAnchor>
                    </div>
                  </div>
                  <div className="inline-flex flex-col items-start justify-start gap-6 rounded-xl bg-grayscalewhite p-4">
                    <div className="inline-flex items-center justify-start gap-6 self-stretch rounded-xl bg-grayscalewhite">
                      <div className="font-oswald text-base font-medium leading-snug text-grayscale900">
                        © 2024
                        <br />
                        Upgrade Labs
                      </div>
                      <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                        <a href={`${externalFranchiseUrlBase}/privacy-policy/`}>Privacy Policy</a>
                      </div>
                      <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                        <a href={`${externalFranchiseUrlBase}/terms-of-service/`}>Terms &amp; Conditions</a>
                      </div>
                    </div>
                    <div className="h-[0px] self-stretch border border-grayscale100"></div>
                    <div className="inline-flex w-full flex-col items-start justify-start gap-6 lg:flex-row lg:gap-3">
                      <div className="inline-flex items-start justify-start gap-3 self-stretch lg:w-[calc(calc(calc(100%-60px)/3)+12px)]">
                        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2">
                          <div className="font-oswald text-base font-medium uppercase leading-snug text-grayscale900">
                            Company
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/about-upgrade-labs/`}>About Us</a>
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/blog/`}>Blog</a>
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/press/`}>Press</a>
                          </div>
                        </div>
                        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2">
                          <div className="font-oswald text-base font-medium uppercase leading-snug text-grayscale900">
                            Technologies
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/technologies/#performance`}>Performance</a>
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/technologies/#recovery`}>Recovery</a>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-start gap-2 self-stretch lg:w-[calc(calc(100%-60px)/6)]">
                        <div className="font-oswald text-base font-medium uppercase leading-snug text-grayscale900">
                          Get Started
                        </div>
                        <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                          <a href={`${externalFranchiseUrlBase}/first-timer/`}>First Upgrade</a>
                        </div>
                        <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                          <NextLink href={`${franchiseHomeRoute}?${tabQueryParamName}=${franchiseTabs.memberships}`}>
                            Memberships
                          </NextLink>
                        </div>
                        <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                          <NextLink href={`${franchiseHomeRoute}?${tabQueryParamName}=${franchiseTabs.upgrades}`}>
                            Booking
                          </NextLink>
                        </div>
                        <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                          <NextLink href={`${franchiseHomeRoute}?${tabQueryParamName}=${franchiseTabs.packages}`}>
                            Packages
                          </NextLink>
                        </div>
                      </div>
                      <div className="inline-flex items-start justify-start gap-3 self-stretch lg:w-[calc(calc(calc(100%-60px)/3)+12px)]">
                        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2">
                          <div className="font-oswald text-base font-medium uppercase leading-snug text-grayscale900">
                            Find Us
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/locations/`}>Locations Map</a>
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/bellevue`}>Bellevue, WA</a>
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/calgary`}>Calgary, AB, CAN</a>
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/coeur-dalene/`}>Coeur d&apos;Alene, ID</a>
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/oakville`}>Oakville - Toronto/GTA, ON</a>
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/park-city/`}>Park City, UT</a>
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/riverton/`}>Riverton, UT</a>
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/5th-street/`}>5th Street - Austin, TX</a>
                          </div>
                        </div>
                        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2">
                          <div className="font-oswald text-base font-medium uppercase leading-snug text-grayscale900">
                            Opportunity
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={externalOwnFranchiseUrlBase} {...newTabLinkProps}>
                              Franchise
                            </a>
                          </div>
                          <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                            <a href={`${externalFranchiseUrlBase}/careers/`}>Careers</a>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-start gap-2 self-stretch lg:w-[calc(calc(100%-60px)/6)]">
                        <div className="font-oswald text-base font-medium uppercase leading-snug text-grayscale900">
                          Support
                        </div>
                        <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                          <a href={`${externalFranchiseUrlBase}/contact/`}>Contact Us</a>
                        </div>
                        <div className="text-[13px] font-medium text-praiseOfShadow transition hover:text-indiaInk">
                          <a href={`${externalFranchiseUrlBase}/faq/`}>FAQ</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </FontOverride>
    </FormProvider>
  )
}
