import { useQueryParam, StringParam } from 'use-query-params'
import { postAuthFormStateQueryParamName } from '../../../../../common/constants/queryParams'
import { FranchiseFullSchema, type UseFranchiseFormReturn } from './useFranchiseForm'
import { type UseFranchiseMembershipReturn } from './useFranchiseMembership'
import { StateSetter } from '../../../../../typescript/utility'
import React from 'react'
import { fields } from '../common/fields'
import { dateOptions } from '../organisms/dateOptions'

type Input = {
  formMethods: UseFranchiseFormReturn
  useFranchiseMembershipReturn: UseFranchiseMembershipReturn
  setIsPaymentScreen: StateSetter<boolean>
}
export function useRepopulatFormPostAuth({ formMethods, useFranchiseMembershipReturn, setIsPaymentScreen }: Input) {
  const { watch, setValue } = formMethods

  const values = watch()

  const serviceFormValues = values[fields.service]
  const dateFormValue = values[fields.date]

  const { timeslotOptions, serviceOptions, membershipOptions, packageOptions } = useFranchiseMembershipReturn

  const [postAuthFormStateQueryParam, setPostAuthFormStateQueryParam] = useQueryParam(
    postAuthFormStateQueryParamName,
    StringParam
  )

  const [decodedFormState, setDecodedFormState] = React.useState<FranchiseFullSchema | null>(null)

  const persistedServiceFormValues = decodedFormState?.[fields.service]
  const persistedDateFormValue = decodedFormState?.[fields.date]
  const persistedTimeslotFormValue = decodedFormState?.[fields.timeslot]
  const persistedPackageFormValue = decodedFormState?.[fields.package]
  const persistedMembershipFormValue = decodedFormState?.[fields.membership]

  const [formPopulatedUpUntilPayment, setFormPopulatedUpUntilPayment] = React.useState(false)

  React.useEffect(() => {
    if (!postAuthFormStateQueryParam) return
    try {
      const decoded = JSON.parse(decodeURIComponent(postAuthFormStateQueryParam))
      setDecodedFormState(decoded)
    } catch {
      return
    } finally {
      setPostAuthFormStateQueryParam(undefined, 'replaceIn')
    }
  }, [postAuthFormStateQueryParam, setPostAuthFormStateQueryParam])

  // location and tab should already be set.

  // if service option in decodedFormState
  //   wait for serviceOptions
  //    if we find the serviceOption and set it
  //     else -> null decodedFormState (handle retry however)
  React.useEffect(() => {
    if (persistedServiceFormValues?.length && serviceOptions.length) {
      const matches = serviceOptions.filter((option) => persistedServiceFormValues.includes(option.value))
      if (matches.length) {
        setValue(
          fields.service,
          matches.map((x) => x.value)
        )
      } else {
        setDecodedFormState(null)
      }
    }
    return () => {}
  }, [persistedServiceFormValues, serviceOptions, setValue])
  //   wait for form service option.
  //     look for date option matching date in decodedFormState. set date if we find it
  //     else -> null decodedFormState (handle retry however)
  React.useEffect(() => {
    if (serviceFormValues.length) {
      const match = dateOptions.find((option) => option.value === persistedDateFormValue)
      if (match) {
        setValue(fields.date, match.value)
      } else {
        setDecodedFormState(null)
      }
    }
  }, [persistedDateFormValue, setValue, serviceFormValues])
  //    wait for date form value and timeslotOptions
  //      look for timeslot option matching timeslot in decodedFormState. set timeslot if we find it and push to payment screen
  //      else -> null decodedFormState (handle retry however)
  React.useEffect(() => {
    if (dateFormValue && timeslotOptions.length) {
      const match = timeslotOptions.find((option) => option.value === persistedTimeslotFormValue)
      if (match) {
        setValue(fields.timeslot, match.value)
        setFormPopulatedUpUntilPayment(true)
      } else {
        setDecodedFormState(null)
      }
    }
  }, [dateFormValue, persistedTimeslotFormValue, timeslotOptions, setValue, setIsPaymentScreen])

  // if package option in decodedFormState
  //    wait for packageOptions
  //      if we find the packageOption and set it
  //      else -> null decodedFormState (handle retry however)
  //    push to payment screen
  React.useEffect(() => {
    if (persistedPackageFormValue && packageOptions.length) {
      const match = packageOptions.find((option) => persistedPackageFormValue === option.value)
      if (match) {
        setValue(fields.package, match.value)
        setFormPopulatedUpUntilPayment(true)
      } else {
        setDecodedFormState(null)
      }
    }
  }, [persistedPackageFormValue, packageOptions, setValue, setIsPaymentScreen])

  //  if membership option in decodedFormState
  //    wait for membershipOptions
  //      if we find the membershipOption and set it
  //      else -> null decodedFormState (handle retry however)
  //    push to payment screen
  React.useEffect(() => {
    if (persistedMembershipFormValue && membershipOptions.length) {
      const match = membershipOptions.find((option) => persistedMembershipFormValue === option.value)
      if (match) {
        setValue(fields.membership, match.value)
        setFormPopulatedUpUntilPayment(true)
      } else {
        setDecodedFormState(null)
      }
    }
  }, [persistedMembershipFormValue, membershipOptions, setValue, setIsPaymentScreen])

  // If we get here we can add additional logic if this is a retry to also add the payment method and actually submit the form.

  React.useEffect(() => {
    if (formPopulatedUpUntilPayment) {
      setFormPopulatedUpUntilPayment(false)
      setIsPaymentScreen(true)
    }
  }, [formPopulatedUpUntilPayment, setFormPopulatedUpUntilPayment, setIsPaymentScreen])
}
