import { StylesConfig } from 'react-select'
import { theme } from '../../../../../styles/theme'
import { franchisePageTheme } from './franchisePageTheme'

export const franchisePagePrimarySelectStyles: (hasError?: boolean) => StylesConfig<any> = (hasError?: boolean) => ({
  control(_base, props) {
    return {
      border: 'none',
      display: 'flex',
      borderRadius: '4px',
      background: props.isFocused ? franchisePageTheme.colors.blankCanvas : 'none',
      transition: 'all 0.3s',
      borderBottom: props.isFocused
        ? `1px solid ${franchisePageTheme.colors.fleurDeSelCaramel};`
        : hasError
          ? `1px solid ${theme.colors.dataRange06PoorText};`
          : `1px solid ${theme.colors.grayscale400};`,
      cursor: 'pointer',
    }
  },
  input(base) {
    return {
      ...base,
      color: theme.colors.grayscale900,
      caretColor: franchisePageTheme.colors.yamabukiGold,
      fontSize: 24,
      fontWeight: 500,
      transition: 'all 0.3s',
    }
  },
  placeholder(base, props) {
    return {
      ...base,
      color: props.isFocused ? theme.colors.grayscale50 : theme.colors.grayscale500,
      fontSize: 24,
      fontWeight: 500,
      transition: 'all 0.3s',
    }
  },
  loadingIndicator: (base) => ({ ...base, color: franchisePageTheme.colors.yamabukiGold }),
  dropdownIndicator: (base) => ({ ...base, color: franchisePageTheme.colors.yamabukiGold }),
  indicatorSeparator: () => ({
    visibility: 'hidden',
  }),
  valueContainer(base) {
    return {
      ...base,
      color: theme.colors.grayscale900,
      fontSize: 24,
      fontWeight: 500,
    }
  },
  singleValue(base) {
    return {
      ...base,
      color: theme.colors.grayscale900,
      fontSize: 24,
      fontWeight: 500,
    }
  },
  menu: (base) => ({
    ...base,
    marginTop: 4,
    background: theme.colors.grayscalewhite,
    border: `none`,
    boxShadow: '2.81794px 2.81794px 14.0897px rgba(111, 140, 176, 0.41)',
    borderRadius: 12,
    overflow: 'hidden',
    zIndex: 2,
  }),
  menuList: (base) => ({ ...base, padding: 0 }),
  option: (base, state) => {
    return {
      ...base,
      border: 'none',
      background:
        state.isSelected || state.isFocused ? franchisePageTheme.colors.blankCanvas : theme.colors.grayscalewhite,
      cursor: 'pointer !important',
      marginTop: '6px',
      marginLeft: '6px',
      marginRight: '6px',
      width: 'calc(100% - 12px)',
      borderRadius: '8px',
      height: '34px',
      padding: '6px',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      color: theme.colors.grayscale900,
      transition: 'all 0.3s',
      ':last-of-type': { marginBottom: 6 },
      ':hover': {
        background: state.isSelected ? franchisePageTheme.colors.blankCanvas : theme.colors.grayscale000,
      },
    }
  },
})
