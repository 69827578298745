/**
 * just a helper for lazy loading the scroll into view module and firing the intended function once it is loaded
 */

export async function scrollIntoView(target: HTMLElement | null, callback?: __ScrollIntoView.Callback): Promise<void>
export async function scrollIntoView(
  target: HTMLElement | null,
  settings: __ScrollIntoView.Settings,
  callback?: __ScrollIntoView.Callback
): Promise<void>
export async function scrollIntoView(...args: unknown[]): Promise<unknown> {
  const scrollIntoViewImport = (await import('scroll-into-view')).default
  if (args[0]) {
    // @ts-ignore don't care about whether ts likes this. we still get inference in the function helper, and we can now
    // use null as the target, which is what we want
    scrollIntoViewImport(...args)
  }
  return
}
